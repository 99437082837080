// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {

  /** CUSTOM CSS **/
  .register-province-container {
    --height: fit-content;
  }

  .sidebar {
    box-shadow: 12px 0 16px -11px #888;
  }

  .safe-padding {
    padding-top: 34px !important;
    // padding-top: constant(safe-area-inset-top);
    // padding-top: env(safe-area-inset-top);
  }

  ion-icon {
    pointer-events: none !important;
  }

  .card-bg {
    height: 100%;
    width: 100%;
    background-size: 100% 100%;
    object-fit: cover;
    background-image: url('../assets/images/atm-card.jpg');
  }

  .credit-bg {
    height: 100%;
    width: 100%;
    background-size: 100% 100%;
    object-fit: cover;
    background-image: url('../assets/images/credit-card.jpg');
  }


  ion-accordion div[slot="content"] {
    border-left: 1px rgb(229 231 235 / 2) solid;
    border-right: 1px rgb(229 231 235 / 2) solid;
    border-bottom: 1px rgb(229 231 235 / 2) solid;
  }


  .breathing-button {
    // width: 180px;
    // padding: 12px;
    // margin: 50px auto;
    // border: 1px solid #3cc8b4;
    -webkit-animation: breathing 3s ease-out infinite normal;
    animation: breathing 3s ease-out infinite normal;
    // font-family: 'Lantinghei SC';
    // font-size: 16px;
    // background: #3cc8b4;
    // color: #fff;
    -webkit-font-smoothing: antialiased;
    // border-radius: 2px;
    // text-align: center;
  }


  @-webkit-keyframes breathing {
    0% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }

    25% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    60% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }

    100% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  }

  @keyframes breathing {
    0% {
      -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
      transform: scale(0.9);
    }

    25% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }

    60% {
      -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
      transform: scale(0.9);
    }

    100% {
      -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
      transform: scale(0.9);
    }
  }

  .swal2-html-container {
    font-size: 14px !important;
    // color: white;
  }

  .swal2-toast {
    // border-radius: 30px !important;
  }

  .swal-close {
    color: #ff4585;
  }

  .swal-title-welcome {
    font-size: 18px;
    font-weight: bold;
    color: #4c4c4c;
  }

  .swal-title-approved {
    font-size: 18px;
    font-weight: bold;
    color: #00DD96;
  }

  .swal-title-denied {
    font-size: 18px;
    font-weight: bold;
    color: #ff4858;
  }

  .swal-title-review {
    font-size: 18px;
    font-weight: bold;
    color: #F9AC18;
  }

  .swal-container {
    font-size: 13px;
  }

  body.swal2-height-auto {
    height: inherit !important;
  }



  // .swal2-toast {
  //   background: #2d2d2d !important;
  //   color: white !important;
  // }



  .main-content {
    // margin-bottom: 110px;
  }

  .max-h-83 {
    max-height: 23rem;
  }

  .card-description {
    max-height: 100px;
    height: 100px;
    overflow: auto;
    padding-right: 10px;
  }


  /**
  Wizard Steps Indicator
  */
  .stepper-container {
    max-width: 1200px;
    margin: 0 auto;
  }

  .step-indicator {
    margin-top: 35px;
    display: flex;
    align-items: center;
    padding: 0 30px;
  }

  .step {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 1;
  }

  .step-indicator .step-icon {
    height: 38px;
    width: 38px;
    border-radius: 50%;
    background: #F5F6F8;
    font-size: 23px;
    text-align: center;
    color: #ffffff;
    position: relative;
  }

  .step.active .step-icon {
    background: #F9AC18;
    -webkit-transition: background 0.4s 0s ease-in;
    -moz-transition: background 0.4s 0s ease-in;
    -o-transition: background 0.4s 0s ease-in;
    transition: background 0.4s 0s ease-in;
  }

  .step.inactive .step-icon {
    background: #F5F6F8;
    -webkit-transition: background 0.4s 0s ease-in;
    -moz-transition: background 0.4s 0s ease-in;
    -o-transition: background 0.4s 0s ease-in;
    transition: background 0.4s 0s ease-in;
  }

  .step-error .step-icon {
    background: #FF4858 !important;
    -webkit-transition: background 0.4s 0s ease-in;
    -moz-transition: background 0.4s 0s ease-in;
    -o-transition: background 0.4s 0s ease-in;
    transition: background 0.4s 0s ease-in;
  }

  .step-error p {
    color: #FF4858 !important;
  }


  .step-icon ion-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 50%;
    transform: translate(-50%, -50%);
    display: block;
  }

  .step p {
    white-space: nowrap;
    text-align: center;
    position: absolute;
    bottom: -34px !important;
    color: #797C87;
    font-size: 14px;
    font-weight: normal;
  }

  .step.active p {
    color: #F9AC18;
  }

  .step.step2 p,
  .step.step3 p {
    left: 50%;
    transform: translateX(-50%);
  }

  .indicator-line {
    width: 100%;
    height: 3px;
    background: #F5F6F8;
    flex: 1;
  }

  .indicator-line.active {
    background: #F9AC18;
  }

  @media screen and (max-width: 500px) {
    .step p {
      font-size: 11px;
      bottom: -20px;
    }
  }



  .warning-alert {
    background-color: #F9AC18;
    color: white;
  }

  .error-alert {
    background-color: #F66E84;
    color: white;
  }

  .success-alert {
    background-color: #1ACD67;
    color: white;

    a {
      color: white;
      text-decoration-line: underline;
    }
  }

  .profile-img {
    border-radius: 100% !important;
    overflow: hidden;
  }

  /* Ripple effect */
  .ripple {
    background-position: center;
    transition: background 0.8s;
  }

  .ripple:hover {
    background: #dfdfdf radial-gradient(circle, transparent 1%, #fff 1%) center/15000%;
  }

  .ripple:active {
    background-color: #fff;
    background-size: 100%;
    transition: background 0s;
  }

  ion-button.disabled {
    --background: #FCD68C !important
  }

  ion-button.disabled:hover {
    --background: #FCD68C !important
  }


  .about-card {
    background-image: url("./../assets/images/tech-life-management.png");
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 136px;
  }

  .faq-card {
    background-image: url("./../assets/images/faq.png");
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 136px;
  }

  // ion-label {
  //   white-space: normal !important;
  // }

  /*
  a {
    color: #F9AC18;
    font-weight: bold;
    font-size: 13px;
  }
  */

  .google-btn {
    --background: #0076FF;
    --background-activated: #0C38E8;
    --background-focused: #0C38E8;
    --background-hover: #0C38E8;
  }

  .facebook-btn {
    --background: #3A5898;
    --background-activated: #3A40B0;
    --background-focused: #3A40B0;
    --background-hover: #3A40B0;
  }


  .vidalia-background {
    --background: url('./../assets/images/vidalia-mobile.png') 0 0/100% 100% no-repeat !important;
    --ion-background-color: none;
  }

  ion-alert {
    --max-width: 300px !important;
    --width: 300px !important;
  }


  .alert-radio-label {
    white-space: pre-wrap;
    font-size: 13px !important;
    padding-left: 36px;
  }

  $font-family-base: "Poppins";

  * {
    font-family: $font-family-base;
  }

  ion-item {
    --border-radius: 10px;
    --background: #ffff;
    --border-color: #E0E0E0;
    --border-width: 1px;
  }

  ion-item.plain {
    --border-radius: 0px;
    --background: rgba(255, 255, 255, 0);
    --border-color: #e0e0e000;
    --border-width: 0px;
  }

  ion-item.error {
    --border-color: #FF4858;
  }

  ion-button {
    --border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
  }

  ion-card-title {
    font-size: 18px;
  }

  ion-card-content {
    font-size: 13px;
  }

  .circle:before {
    content: '\25CF';
    font-size: 40px;
    color: #F9AC18;
  }

  .circle-notification {
    margin-top: -26px;
    left: -12px;
    margin-left: -18px;
    z-index: 1;
  }

  // input:focus {
  //   // outline: none !important;
  //   border: 0px !important;
  // }

  input {
    font-size: 13px !important;
  }

  ion-item {
    ion-label {
      font-size: 14px !important;
      color: #797C87 !important;
    }



    ion-datetime {
      font-size: 13px !important;
    }

    ion-select {
      font-size: 13px !important;
    }

    // .label-floating {
    //   margin-bottom: -24px !important;
    // }
  }



  .no-scroll {
    --padding-top: 0px;
    --padding-bottom: 0px;
  }

  .dashboard {
    background-color: #E5E5E5;
  }

  .dashboard-header {
    --border-width: 0px !important
  }


  .w-250 {
    width: 250px;
  }

  // .text-797C87 {
  //   color: #797C87;
  // }

  // .text-F9AC18 {
  //   color: #F9AC18;
  // }

  // .text-34px {
  //   font-size: 34px;
  // }

  // .text-60 {
  //   font-size: 60px !important;
  // }

  // .text-20px {
  //   font-size: 20px;
  // }

  // .text-14px {
  //   font-size: 14px !important;
  // }

  // .text-11px {
  //   font-size: 11px !important;
  // }

  // .text-18px {
  //   font-size: 18px !important;
  // }

  // .text-13px {
  //   font-size: 13px !important;
  // }

  .bottom-25 {
    bottom: 25px !important;
  }

  .swiper-pagination-bullet-active {
    width: 40px !important;
    border-radius: 18px !important;
  }

  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal>.swiper-pagination-bullets {
    bottom: 185px;
    left: 0;
    width: 100%;
  }

  .w-300 {
    width: 300px;
  }


  .border-1 {
    border-width: 1px;
  }

  .border-b-1 {
    border-bottom-width: 1px;
  }

  .rounded-4 {
    border-radius: 4px;
  }

  .pane {
    border-radius: 20px !important;
  }

  /*
  .cupertino-pane table {
    font-size: 14px;
    width: 100%;
  }

  .cupertino-pane-wrapper .move {
    margin-top: 12px;
  }
  */

  .bottom-dashed {
    border-bottom: 1px dashed #ABB4BD;
  }


  ion-content {
    --ion-background-color: #fff;
  }

  .ios ion-tab-bar {
    height: 4rem !important;
    box-shadow: 0px -8px 25px 0px #00000021;
  }

  .accordion-active {
    display: block;
  }

  .accordion-inactive {
    display: none;
  }

  .dashedSpace {
    width: 350px;
    height: 1px;
    margin-top: 10px;
    background-image: linear-gradient(to right, #f20afb 0%, #f20afb 50%, transparent 50%);
    background-size: 28px 1px;
    background-repeat: repeat-x;
  }

  .linear {
    background-image: linear-gradient(to right, #ccc 0%, #f20afb 50%, transparent 50%);
    background-size: 40px 1px;
  }

  .leading-0 {
    line-height: unset;
  }


  .swiper-pagination {
    position: absolute;
    margin-bottom: -178px;

  }




  /** primary **/
  --ion-color-primary: #F9AC18;
  --ion-color-primary-rgb: 249,
  172,
  24;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 0,
  0,
  0;
  --ion-color-primary-shade: #db9715;
  --ion-color-primary-tint: #fab42f;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61,
  194,
  255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,
  255,
  255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82,
  96,
  255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,
  255,
  255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45,
  211,
  111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,
  255,
  255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,
  196,
  9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,
  0,
  0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #ff4858;
  --ion-color-danger-rgb: 235,
  68,
  90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,
  255,
  255;
  --ion-color-danger-shade: #ff4858;
  --ion-color-danger-tint: #ff4858;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,
  36,
  40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,
  255,
  255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146,
  148,
  156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,
  255,
  255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,
  245,
  248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,
  0,
  0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}


.shake {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

ion-item {
  --highlight-background: #F9AC18 !important;
}

ion-accordion.accordion-expanding ion-item[slot="header"],
ion-accordion.accordion-expanded ion-item[slot="header"] {
  --color: #f9ac18;
}


.loan-card {
  background-image: linear-gradient(49.44deg, #BD6603 -5.76%, rgba(249, 172, 24, 0.7) 102.33%), url(./../assets/images/loan-card.png);
  height: auto;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  background-blend-mode: multiply;

  ion-item {
    --background: transparent;
    padding: 0px;
    margin: 0px
  }

  .loan-card-menu {
    float: right;
    right: 9px;
    position: absolute;
  }
}

.introjs-prevbutton {
  border-width: 0px !important;
  text-shadow: none !important;
}

.introjs-nextbutton {
  background-color: #F9AC18 !important;
  color: white !important;
  border-width: 0px !important;
  text-shadow: none !important;
}

.introjs-skipbutton {
  display: none !important;
}

// @media (prefers-color-scheme: light) {
//   /*
//    * Dark Colors
//    * -------------------------------------------
//    */

//   body {
//     --ion-color-primary: #428cff;
//     --ion-color-primary-rgb: 66, 140, 255;
//     --ion-color-primary-contrast: #ffffff;
//     --ion-color-primary-contrast-rgb: 255, 255, 255;
//     --ion-color-primary-shade: #3a7be0;
//     --ion-color-primary-tint: #5598ff;

//     --ion-color-secondary: #50c8ff;
//     --ion-color-secondary-rgb: 80, 200, 255;
//     --ion-color-secondary-contrast: #ffffff;
//     --ion-color-secondary-contrast-rgb: 255, 255, 255;
//     --ion-color-secondary-shade: #46b0e0;
//     --ion-color-secondary-tint: #62ceff;

//     --ion-color-tertiary: #6a64ff;
//     --ion-color-tertiary-rgb: 106, 100, 255;
//     --ion-color-tertiary-contrast: #ffffff;
//     --ion-color-tertiary-contrast-rgb: 255, 255, 255;
//     --ion-color-tertiary-shade: #5d58e0;
//     --ion-color-tertiary-tint: #7974ff;

//     --ion-color-success: #2fdf75;
//     --ion-color-success-rgb: 47, 223, 117;
//     --ion-color-success-contrast: #000000;
//     --ion-color-success-contrast-rgb: 0, 0, 0;
//     --ion-color-success-shade: #29c467;
//     --ion-color-success-tint: #44e283;

//     --ion-color-warning: #ffd534;
//     --ion-color-warning-rgb: 255, 213, 52;
//     --ion-color-warning-contrast: #000000;
//     --ion-color-warning-contrast-rgb: 0, 0, 0;
//     --ion-color-warning-shade: #e0bb2e;
//     --ion-color-warning-tint: #ffd948;

//     --ion-color-danger: #ff4961;
//     --ion-color-danger-rgb: 255, 73, 97;
//     --ion-color-danger-contrast: #ffffff;
//     --ion-color-danger-contrast-rgb: 255, 255, 255;
//     --ion-color-danger-shade: #e04055;
//     --ion-color-danger-tint: #ff5b71;

//     --ion-color-dark: #f4f5f8;
//     --ion-color-dark-rgb: 244, 245, 248;
//     --ion-color-dark-contrast: #000000;
//     --ion-color-dark-contrast-rgb: 0, 0, 0;
//     --ion-color-dark-shade: #d7d8da;
//     --ion-color-dark-tint: #f5f6f9;

//     --ion-color-medium: #989aa2;
//     --ion-color-medium-rgb: 152, 154, 162;
//     --ion-color-medium-contrast: #000000;
//     --ion-color-medium-contrast-rgb: 0, 0, 0;
//     --ion-color-medium-shade: #86888f;
//     --ion-color-medium-tint: #a2a4ab;

//     --ion-color-light: #222428;
//     --ion-color-light-rgb: 34, 36, 40;
//     --ion-color-light-contrast: #ffffff;
//     --ion-color-light-contrast-rgb: 255, 255, 255;
//     --ion-color-light-shade: #1e2023;
//     --ion-color-light-tint: #383a3e;
//   }

//   /*
//    * iOS Dark Theme
//    * -------------------------------------------
//    */

//   .ios body {
//     --ion-background-color: #000000;
//     --ion-background-color-rgb: 0, 0, 0;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255, 255, 255;

//     --ion-color-step-50: #0d0d0d;
//     --ion-color-step-100: #1a1a1a;
//     --ion-color-step-150: #262626;
//     --ion-color-step-200: #333333;
//     --ion-color-step-250: #404040;
//     --ion-color-step-300: #4d4d4d;
//     --ion-color-step-350: #595959;
//     --ion-color-step-400: #666666;
//     --ion-color-step-450: #737373;
//     --ion-color-step-500: #808080;
//     --ion-color-step-550: #8c8c8c;
//     --ion-color-step-600: #999999;
//     --ion-color-step-650: #a6a6a6;
//     --ion-color-step-700: #b3b3b3;
//     --ion-color-step-750: #bfbfbf;
//     --ion-color-step-800: #cccccc;
//     --ion-color-step-850: #d9d9d9;
//     --ion-color-step-900: #e6e6e6;
//     --ion-color-step-950: #f2f2f2;

//     --ion-item-background: #000000;

//     --ion-card-background: #1c1c1d;
//   }

//   .ios ion-modal {
//     --ion-background-color: var(--ion-color-step-100);
//     --ion-toolbar-background: var(--ion-color-step-150);
//     --ion-toolbar-border-color: var(--ion-color-step-250);
//   }


//   /*
//    * Material Design Dark Theme
//    * -------------------------------------------
//    */

//   .md body {
//     --ion-background-color: #121212;
//     --ion-background-color-rgb: 18, 18, 18;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255, 255, 255;

//     --ion-border-color: #222222;

//     --ion-color-step-50: #1e1e1e;
//     --ion-color-step-100: #2a2a2a;
//     --ion-color-step-150: #363636;
//     --ion-color-step-200: #414141;
//     --ion-color-step-250: #4d4d4d;
//     --ion-color-step-300: #595959;
//     --ion-color-step-350: #656565;
//     --ion-color-step-400: #717171;
//     --ion-color-step-450: #7d7d7d;
//     --ion-color-step-500: #898989;
//     --ion-color-step-550: #949494;
//     --ion-color-step-600: #a0a0a0;
//     --ion-color-step-650: #acacac;
//     --ion-color-step-700: #b8b8b8;
//     --ion-color-step-750: #c4c4c4;
//     --ion-color-step-800: #d0d0d0;
//     --ion-color-step-850: #dbdbdb;
//     --ion-color-step-900: #e7e7e7;
//     --ion-color-step-950: #f3f3f3;

//     --ion-item-background: #1e1e1e;

//     --ion-toolbar-background: #1f1f1f;

//     --ion-tab-bar-background: #1f1f1f;

//     --ion-card-background: #1e1e1e;
//   }
// }
