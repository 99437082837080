/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

// @import '~swiper/scss';
// @import '~swiper/scss/virtual';
// @import '~swiper/scss/pagination';
// @import '~swiper/css/zoom';

@import 'swiper/scss';
@import 'swiper/scss/virtual';
@import 'swiper/scss/pagination';
@import 'swiper/css/zoom';


ion-modal {
  --background: #4c4c4c
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-ExtraBold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-SemiBold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Light.ttf");
  font-weight: 300;
}

a {
  color: #F9AC18;
  font-weight: bold;
  font-size: 13px;
}
