@charset "UTF-8";
/** Ionic CSS Variables **/
:root {
  /** CUSTOM CSS **/
  /**
  Wizard Steps Indicator
  */
  /* Ripple effect */
  /*
  a {
    color: #F9AC18;
    font-weight: bold;
    font-size: 13px;
  }
  */
  /*
  .cupertino-pane table {
    font-size: 14px;
    width: 100%;
  }

  .cupertino-pane-wrapper .move {
    margin-top: 12px;
  }
  */
  /** primary **/
  --ion-color-primary: #F9AC18;
  --ion-color-primary-rgb: 249,
  172,
  24;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 0,
  0,
  0;
  --ion-color-primary-shade: #db9715;
  --ion-color-primary-tint: #fab42f;
  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61,
  194,
  255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,
  255,
  255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;
  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82,
  96,
  255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,
  255,
  255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;
  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45,
  211,
  111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,
  255,
  255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,
  196,
  9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,
  0,
  0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  /** danger **/
  --ion-color-danger: #ff4858;
  --ion-color-danger-rgb: 235,
  68,
  90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,
  255,
  255;
  --ion-color-danger-shade: #ff4858;
  --ion-color-danger-tint: #ff4858;
  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,
  36,
  40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,
  255,
  255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146,
  148,
  156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,
  255,
  255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,
  245,
  248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,
  0,
  0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}
:root .register-province-container {
  --height: fit-content;
}
:root .sidebar {
  box-shadow: 12px 0 16px -11px #888;
}
:root .safe-padding {
  padding-top: 34px !important;
}
:root ion-icon {
  pointer-events: none !important;
}
:root .card-bg {
  height: 100%;
  width: 100%;
  background-size: 100% 100%;
  object-fit: cover;
  background-image: url("../assets/images/atm-card.jpg");
}
:root .credit-bg {
  height: 100%;
  width: 100%;
  background-size: 100% 100%;
  object-fit: cover;
  background-image: url("../assets/images/credit-card.jpg");
}
:root ion-accordion div[slot=content] {
  border-left: 1px rgb(229, 231, 235) solid;
  border-right: 1px rgb(229, 231, 235) solid;
  border-bottom: 1px rgb(229, 231, 235) solid;
}
:root .breathing-button {
  -webkit-animation: breathing 3s ease-out infinite normal;
  animation: breathing 3s ease-out infinite normal;
  -webkit-font-smoothing: antialiased;
}
@-webkit-keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  60% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
  25% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}
:root .swal2-html-container {
  font-size: 14px !important;
}
:root .swal-close {
  color: #ff4585;
}
:root .swal-title-welcome {
  font-size: 18px;
  font-weight: bold;
  color: #4c4c4c;
}
:root .swal-title-approved {
  font-size: 18px;
  font-weight: bold;
  color: #00DD96;
}
:root .swal-title-denied {
  font-size: 18px;
  font-weight: bold;
  color: #ff4858;
}
:root .swal-title-review {
  font-size: 18px;
  font-weight: bold;
  color: #F9AC18;
}
:root .swal-container {
  font-size: 13px;
}
:root body.swal2-height-auto {
  height: inherit !important;
}
:root .max-h-83 {
  max-height: 23rem;
}
:root .card-description {
  max-height: 100px;
  height: 100px;
  overflow: auto;
  padding-right: 10px;
}
:root .stepper-container {
  max-width: 1200px;
  margin: 0 auto;
}
:root .step-indicator {
  margin-top: 35px;
  display: flex;
  align-items: center;
  padding: 0 30px;
}
:root .step {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
}
:root .step-indicator .step-icon {
  height: 38px;
  width: 38px;
  border-radius: 50%;
  background: #F5F6F8;
  font-size: 23px;
  text-align: center;
  color: #ffffff;
  position: relative;
}
:root .step.active .step-icon {
  background: #F9AC18;
  -webkit-transition: background 0.4s 0s ease-in;
  -moz-transition: background 0.4s 0s ease-in;
  -o-transition: background 0.4s 0s ease-in;
  transition: background 0.4s 0s ease-in;
}
:root .step.inactive .step-icon {
  background: #F5F6F8;
  -webkit-transition: background 0.4s 0s ease-in;
  -moz-transition: background 0.4s 0s ease-in;
  -o-transition: background 0.4s 0s ease-in;
  transition: background 0.4s 0s ease-in;
}
:root .step-error .step-icon {
  background: #FF4858 !important;
  -webkit-transition: background 0.4s 0s ease-in;
  -moz-transition: background 0.4s 0s ease-in;
  -o-transition: background 0.4s 0s ease-in;
  transition: background 0.4s 0s ease-in;
}
:root .step-error p {
  color: #FF4858 !important;
}
:root .step-icon ion-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50%;
  transform: translate(-50%, -50%);
  display: block;
}
:root .step p {
  white-space: nowrap;
  text-align: center;
  position: absolute;
  bottom: -34px !important;
  color: #797C87;
  font-size: 14px;
  font-weight: normal;
}
:root .step.active p {
  color: #F9AC18;
}
:root .step.step2 p,
:root .step.step3 p {
  left: 50%;
  transform: translateX(-50%);
}
:root .indicator-line {
  width: 100%;
  height: 3px;
  background: #F5F6F8;
  flex: 1;
}
:root .indicator-line.active {
  background: #F9AC18;
}
@media screen and (max-width: 500px) {
  :root .step p {
    font-size: 11px;
    bottom: -20px;
  }
}
:root .warning-alert {
  background-color: #F9AC18;
  color: white;
}
:root .error-alert {
  background-color: #F66E84;
  color: white;
}
:root .success-alert {
  background-color: #1ACD67;
  color: white;
}
:root .success-alert a {
  color: white;
  text-decoration-line: underline;
}
:root .profile-img {
  border-radius: 100% !important;
  overflow: hidden;
}
:root .ripple {
  background-position: center;
  transition: background 0.8s;
}
:root .ripple:hover {
  background: #dfdfdf radial-gradient(circle, transparent 1%, #fff 1%) center/15000%;
}
:root .ripple:active {
  background-color: #fff;
  background-size: 100%;
  transition: background 0s;
}
:root ion-button.disabled {
  --background: #FCD68C !important ;
}
:root ion-button.disabled:hover {
  --background: #FCD68C !important ;
}
:root .about-card {
  background-image: url("./../assets/images/tech-life-management.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 136px;
}
:root .faq-card {
  background-image: url("./../assets/images/faq.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 136px;
}
:root .google-btn {
  --background: #0076FF;
  --background-activated: #0C38E8;
  --background-focused: #0C38E8;
  --background-hover: #0C38E8;
}
:root .facebook-btn {
  --background: #3A5898;
  --background-activated: #3A40B0;
  --background-focused: #3A40B0;
  --background-hover: #3A40B0;
}
:root .vidalia-background {
  --background: url("./../assets/images/vidalia-mobile.png") 0 0/100% 100% no-repeat !important;
  --ion-background-color: none;
}
:root ion-alert {
  --max-width: 300px !important;
  --width: 300px !important;
}
:root .alert-radio-label {
  white-space: pre-wrap;
  font-size: 13px !important;
  padding-left: 36px;
}
:root * {
  font-family: "Poppins";
}
:root ion-item {
  --border-radius: 10px;
  --background: #ffff;
  --border-color: #E0E0E0;
  --border-width: 1px;
}
:root ion-item.plain {
  --border-radius: 0px;
  --background: rgba(255, 255, 255, 0);
  --border-color: #e0e0e000;
  --border-width: 0px;
}
:root ion-item.error {
  --border-color: #FF4858;
}
:root ion-button {
  --border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
}
:root ion-card-title {
  font-size: 18px;
}
:root ion-card-content {
  font-size: 13px;
}
:root .circle:before {
  content: "●";
  font-size: 40px;
  color: #F9AC18;
}
:root .circle-notification {
  margin-top: -26px;
  left: -12px;
  margin-left: -18px;
  z-index: 1;
}
:root input {
  font-size: 13px !important;
}
:root ion-item ion-label {
  font-size: 14px !important;
  color: #797C87 !important;
}
:root ion-item ion-datetime {
  font-size: 13px !important;
}
:root ion-item ion-select {
  font-size: 13px !important;
}
:root .no-scroll {
  --padding-top: 0px;
  --padding-bottom: 0px;
}
:root .dashboard {
  background-color: #E5E5E5;
}
:root .dashboard-header {
  --border-width: 0px !important ;
}
:root .w-250 {
  width: 250px;
}
:root .bottom-25 {
  bottom: 25px !important;
}
:root .swiper-pagination-bullet-active {
  width: 40px !important;
  border-radius: 18px !important;
}
:root .swiper-pagination-fraction,
:root .swiper-pagination-custom,
:root .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 185px;
  left: 0;
  width: 100%;
}
:root .w-300 {
  width: 300px;
}
:root .border-1 {
  border-width: 1px;
}
:root .border-b-1 {
  border-bottom-width: 1px;
}
:root .rounded-4 {
  border-radius: 4px;
}
:root .pane {
  border-radius: 20px !important;
}
:root .bottom-dashed {
  border-bottom: 1px dashed #ABB4BD;
}
:root ion-content {
  --ion-background-color: #fff;
}
:root .ios ion-tab-bar {
  height: 4rem !important;
  box-shadow: 0px -8px 25px 0px rgba(0, 0, 0, 0.1294117647);
}
:root .accordion-active {
  display: block;
}
:root .accordion-inactive {
  display: none;
}
:root .dashedSpace {
  width: 350px;
  height: 1px;
  margin-top: 10px;
  background-image: linear-gradient(to right, #f20afb 0%, #f20afb 50%, transparent 50%);
  background-size: 28px 1px;
  background-repeat: repeat-x;
}
:root .linear {
  background-image: linear-gradient(to right, #ccc 0%, #f20afb 50%, transparent 50%);
  background-size: 40px 1px;
}
:root .leading-0 {
  line-height: unset;
}
:root .swiper-pagination {
  position: absolute;
  margin-bottom: -178px;
}

.shake {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;
  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
ion-item {
  --highlight-background: #F9AC18 !important;
}

ion-accordion.accordion-expanding ion-item[slot=header],
ion-accordion.accordion-expanded ion-item[slot=header] {
  --color: #f9ac18;
}

.loan-card {
  background-image: linear-gradient(49.44deg, #BD6603 -5.76%, rgba(249, 172, 24, 0.7) 102.33%), url(./../assets/images/loan-card.png);
  height: auto;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  background-blend-mode: multiply;
}
.loan-card ion-item {
  --background: transparent;
  padding: 0px;
  margin: 0px;
}
.loan-card .loan-card-menu {
  float: right;
  right: 9px;
  position: absolute;
}

.introjs-prevbutton {
  border-width: 0px !important;
  text-shadow: none !important;
}

.introjs-nextbutton {
  background-color: #F9AC18 !important;
  color: white !important;
  border-width: 0px !important;
  text-shadow: none !important;
}

.introjs-skipbutton {
  display: none !important;
}